import { useQuery } from "@tanstack/react-query";
import { MethodDetailDto, MethodDetailListDto, MethodMeasureTimerange } from "api-shared";
import queryString from "query-string";
import { matomoTrackSiteSearch } from "../../infrastructure/tracking";
import { apiGet } from "../../lib/api";
import { MethodQueryKeys } from "./query-keys";

type UseMethodsOptions = {
    searchKey?: string;
    timerange?: MethodMeasureTimerange;
};

export const useMethodsQuery = (options: UseMethodsOptions = {}) => {
    return useQuery({
        queryKey: [...MethodQueryKeys.list(), options] as const,
        queryFn: ({ queryKey, signal }) => {
            const query = queryString.stringify(queryKey[2]);
            return apiGet<MethodDetailListDto>(`methods?${query}`, { signal });
        },
        onSuccess: (methods) => {
            const { searchKey } = options;

            if (searchKey !== undefined && searchKey !== "") {
                matomoTrackSiteSearch(searchKey, "method-explorer", methods.length);
            }
        },
    });
};

type UseMethodOptions = {
    methodId?: number;
};

export const useMethodQuery = ({ methodId }: UseMethodOptions) => {
    return useQuery({
        queryKey: MethodQueryKeys.getById(methodId),
        // queryKey[2] (aka methodId) cannot be null because of enabled flag
        queryFn: ({ queryKey, signal }) => apiGet<MethodDetailDto>(`methods/${queryKey[2] ?? 0}`, { signal }),
        enabled: methodId !== undefined,
    });
};
